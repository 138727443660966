import { useEffect, useState } from "react";

function DayCalculator({ notionObject }) {
  const [daysSince, setDaysSince] = useState(null);
  useEffect(() => {
    if (notionObject) {
      const diffInMs = Math.abs(new Date() - new Date(notionObject));
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      setDaysSince(Math.round(diffInDays));
    }
  }, [notionObject]);

  return (
    <div className="days-since-text">
      {daysSince ? (
        <h2>{daysSince} days since</h2>
      ) : (
        <h2>Please select start date</h2>
      )}
    </div>
  );
}

export default DayCalculator;
