import { useState } from "react";
import { NOTION_TOGETHER_SINCE_KEY } from "../constants";

function useLocalStorage() {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return {};
    }
    try {
      const item = window.localStorage.getItem(NOTION_TOGETHER_SINCE_KEY);
      return item ? JSON.parse(item) : {};
    } catch (error) {
      console.log(error);
      return {};
    }
  });

  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(
          NOTION_TOGETHER_SINCE_KEY,
          JSON.stringify(valueToStore)
        );
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return { storedValue, setValue };
}

export default useLocalStorage;
