import "./App.css";

import Settings from "./components/Settings";

import Polaroid from "./components/Polaroid";
import useLocalStorage from "./hooks/useLocalStorage";

function App() {
  const { storedValue, setValue } = useLocalStorage();

  return (
    <div className="App">
      <Polaroid notionObject={storedValue} />
      <Settings notionObject={storedValue} setValue={setValue} />
    </div>
  );
}

export default App;
