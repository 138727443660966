import { useForm } from "react-hook-form";

import DayConfigurator from "./DayConfigurator";

// import FileUpload from "./FileUpload";

function SettingsForm({ notionObject, onFormSubmit }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      date: notionObject,
    },
  });
  const onSubmit = (data) => {
    const { date } = data;
    if (!date.length) setError("date", { message: "Please select a date" });
    onFormSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DayConfigurator name="date" register={register} />
      {/* <FileUpload name="imageFile" register={register} /> */}
      {errors && errors.date ? <div>Please select a date</div> : null}
      <button type="submit" className="form-submit-button">
        Save
      </button>
    </form>
  );
}

export default SettingsForm;
