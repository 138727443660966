function DayConfigurator({ name, register }) {
  return (
    <div className="day-configurator">
      <label htmlFor="start">Please select a start date:</label>
      <input
        id="start"
        type="date"
        name="start-date"
        min=""
        max={new Date()}
        {...register(name)}
      />
    </div>
  );
}

export default DayConfigurator;
