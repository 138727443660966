import { useEffect, useState } from "react";
import Modal from "react-modal";

import SettingsIcon from "../assets/settings.png";

import SettingsForm from "./SettingsForm";

function Settings({ notionObject, setValue }) {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  function handleSubmit(data) {
    if (data.date) setValue(data.date);
    closeModal();
  }

  useEffect(() => {
    if (notionObject?.length) closeModal();
  }, [notionObject]);

  return (
    <div>
      <Modal
        className="settings-modal"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Settings Modal"
      >
        <SettingsForm notionObject={notionObject} onFormSubmit={handleSubmit} />
      </Modal>
      {!modalIsOpen && (
        <div className="settings-wrapper">
          <button
            className="settings-icon-button"
            type="button"
            onClick={openModal}
          >
            <img
              className="settings-icon-image"
              src={SettingsIcon}
              alt="Settings icon"
            />
          </button>
        </div>
      )}
    </div>
  );
}

export default Settings;
